import React from 'react';
import Grid from '@material-ui/core/Grid';
// import { __ } from 'artsteps2-common';
import InputField from '../../generic/forms/InputField';
import TagsInput from '../fields/TagsInput';
import { VerticalItems } from './FormStyled';
import InputSelect from '../../generic/forms/InputSelect';
import Dimensions from '../fields/Dimensions';
import InteractiveField from '../fields/InteractiveField';
import CanvasField from '../fields/CanvasField';

const ImageEditor = ({ formData, licenses, handleChange, file }) => {
  return (
    <Grid item xs={12} md={6}>
      <VerticalItems>
        <InputField
          required
          handleChange={handleChange}
          value={formData.title}
          name="title"
          label="Title"
        />
        <InputField
          handleChange={handleChange}
          value={formData.caption}
          name="caption"
          textArea
          label={
            <>
              Description{' '}
              <small style={{ fontSize: '12px', color: 'red' }}>
                (Use hyperlinks by acquiring a premium 3D space template)
              </small>
            </>
          }
        />
        <InteractiveField
          formData={formData}
          handleChange={handleChange}
          checked={formData.model && formData.model.nonInteractive}
        />
        <CanvasField
          formData={formData}
          handleChange={handleChange}
          checked={formData.model && formData.model.hasCanvas}
        />
        <Dimensions
          handleChange={handleChange}
          formData={formData}
          dimensions={['height', 'width']}
        />
        <TagsInput
          tags={formData.tags}
          handleChange={handleChange}
          label="Artifact Tags"
          hint="Categorize your artifacts"
        />
        <InputSelect
          value={formData.license}
          handleChange={handleChange}
          label="Licenses"
          hint="Artifact License"
          name="license"
          options={licenses}
        />
      </VerticalItems>
    </Grid>
  );
};

export default ImageEditor;
